.report-heading{width: auto ; float: left}
button.accordion-button {
    font-size: 15px;
    background-color: rgb(68 73 209);
    color: #fff;
    padding: 10px;
    border: 1px solid #ddd;
}
.pt-3.d-flex.justify-content-between.col {
    float: right;
    padding: 10px 20px 30px;
}
button.btn.btn-warning {
    margin: 5px;
}
.mt-3.row {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
}
h2{
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.42857143;
    font-weight: normal;
}

table tr th{
    padding-left: 16px;
    padding-right: 26px;
}
table tr td{
    padding-left: 16px;
    padding-right: 26px;
}
.panel-header button{
    font-size: 18px;
    font-weight: 500;
    border-radius: 22px;
    background-image: linear-gradient(to right, #43cea2 0%, #185a9d  51%, #43cea2  100%);
    margin: 10px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    color: white;            
    box-shadow: 0 0 20px #eee;
    padding-top: 0px;
    padding-bottom: 0px
  }

.panel-header-active button{
    border-radius: 50px;
    margin-left: -214px;
    margin-top: 26px;
    background-color: #43cea2;
}

.panel-header button:hover {
background-position: right center; /* change the direction of the change here */
color: #fff;
text-decoration: none;
}

.panel-prescription-body{
    padding-bottom: 10px;
    padding-top: 10px;
}
 
 